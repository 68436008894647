import type { JSX, PropsWithChildren } from "react";
import { useMemo } from "react";
import { clsx } from "../utils/clsx";

interface DeviceOnlyProps {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  inline?: boolean;
}

type HeadingSize = "h1" | "h2" | "h3" | "h4";
export type HeadingProps = PropsWithChildren<
  {
    as?: "div" | "span" | HeadingSize;
    size?: HeadingSize;
    variant?: "blog";
    centered?: boolean;
    className?: string;
    display?: boolean;
  } & DeviceOnlyProps
>;

// interface HelpersProps {
//   h2: React.FC<Omit<HeadingProps, "size">>;
//   h3: React.FC<Omit<HeadingProps, "size">>;
//   h4: React.FC<Omit<HeadingProps, "size">>;
//   blog: React.FC<Omit<HeadingProps, "size">>;
//   blogH2: React.FC<Omit<HeadingProps, "size">>;
//   blogH3: React.FC<Omit<HeadingProps, "size">>;
// }

export function Heading({
  as,
  size = "h1",
  variant,
  centered = false,
  className,
  mobileOnly,
  desktopOnly,
  inline,
  display = false,
  ...props
}: HeadingProps): JSX.Element {
  const Component = as ?? size;

  const sizeClasses = useMemo(() => {
    // display sizing
    if (display) {
      return clsx(
        size === "h1" && "text-6xl md:text-9xl",
        size === "h2" && "text-3xl md:text-7xl",
        size === "h3" && "text-2xl md:text-4xl",
        size === "h4" && "text-xl md:text-2xl",
      );
    }
    // custom sizing for blog
    if (variant === "blog") {
      return clsx(
        size === "h1" && "text-[19px] lg:text-6xl",
        size === "h2" && "text-xl xl:text-2xl",
        size === "h3" && "text-lg xl:text-xl",
        size === "h4" && "text-lg xl:text-xl",
      );
    }
    // default sizing
    return clsx(
      size === "h1" && "text-5xl md:text-7xl",
      size === "h2" && "text-3xl md:text-6xl",
      size === "h3" && "text-2xl md:text-4xl",
      size === "h4" && "text-xl md:text-2xl",
    );
  }, [size, variant, display]);

  return (
    <Component
      className={clsx(
        "font-bold text-balance",
        sizeClasses,
        centered && "text-center",
        mobileOnly && `${inline ? "inline" : "block"} md:hidden`,
        desktopOnly && `hidden md:${inline ? "inline" : "block"}`,
        className,
      )}
      {...props}
    />
  );
}

Heading.h2 = (props: HeadingProps) => <Heading {...props} size="h2" />;
Heading.h3 = (props: HeadingProps) => <Heading {...props} size="h3" />;
Heading.h4 = (props: HeadingProps) => <Heading {...props} size="h4" />;
Heading.blog = (props: HeadingProps) => <Heading {...props} variant="blog" />;
Heading.blogH2 = (props: HeadingProps) => (
  <Heading {...props} size="h2" variant="blog" />
);
Heading.blogH3 = (props: HeadingProps) => (
  <Heading {...props} size="h3" variant="blog" />
);

export type ParagraphSizes = "sub" | "sub2" | "sub3" | "p1" | "p2" | "p3";

export type ParagraphProps = PropsWithChildren<
  DeviceOnlyProps
  & Omit<React.HTMLProps<HTMLParagraphElement>, 'size'>
  & {
    as?: "p" | "div" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    size?: ParagraphSizes;
  }
>;

export function Paragraph({
  size = "p1",
  as: Component = "p",
  className,
  mobileOnly,
  desktopOnly,
  inline,
  ...props
}: ParagraphProps): JSX.Element {
  return (
    <Component
      className={clsx(
        size === "sub" && "text-3xl font-bold md:text-4xl lg:text-4xl",
        size === "sub2" && "text-xl font-bold md:text-2xl lg:text-2xl",
        size === "sub3" && "text-base font-bold",
        size === "p1" && "text-lg font-normal",
        size === "p2" && "text-base font-normal",
        size === "p3" && "text-sm font-normal",
        mobileOnly && `${inline ? "inline" : "block"} md:hidden`,
        desktopOnly && `hidden md:${inline ? "inline" : "block"}`,
        className,
      )}
      {...props}
    />
  );
}

type SpanProps = PropsWithChildren<
  {
    className?: string;
  } & DeviceOnlyProps
>;

export function Span({
  className,
  mobileOnly,
  desktopOnly,
  children,
}: SpanProps): JSX.Element {
  return (
    <span
      className={clsx(
        className,
        mobileOnly && "inline md:hidden",
        desktopOnly && "hidden md:inline",
      )}
    >
      {children}
    </span>
  );
}

export function Underlined({ className, ...props }: SpanProps): JSX.Element {
  return (
    <Span
      className={clsx(
        "decoration-cgc-yellow font-bold underline decoration-2",
        className,
      )}
      {...props}
    />
  );
}
