import type { JSX } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { clsx } from "../utils/clsx";

export interface SpinnerProps {
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
  variant?: "loading" | "arrows";
}

function LoadingIcon({ className }: { className: string }): JSX.Element {
  return (
    <svg className={className} fill="none" viewBox="0 0 24 24">
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Spinner({
  className,
  size = "sm",
  variant = "loading",
}: SpinnerProps): JSX.Element {
  const props = {
    className: clsx(
      "text-cgc-green m-3 mx-5 h-5 w-5 animate-spin",
      size === "sm" && "h-5 w-5",
      size === "md" && "h-8 w-8",
      size === "lg" && "h-12 w-12",
      size === "xl" && "h-16 w-16",
      className,
    ),
  };
  if (variant === "loading") {
    return <LoadingIcon {...props} />;
  }
  return <ArrowPathIcon {...props} />;
}
