import type { ComponentProps, JSX, PropsWithChildren, ReactNode } from "react";
import { clsx } from "../utils/clsx";
import { Spinner } from './spinner';
import type { ParagraphSizes } from "./typography";
import { Paragraph } from "./typography";

export type ButtonColors =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "whiteOutline"
  | "link"
  | "unstyled"
  | "blog"
  | "yellow"
  | "christmasGreen";

export type ButtonProps = PropsWithChildren<{
  color?: ButtonColors;
  outline?: boolean;
  isLoading?: boolean;
  typoSize?: ParagraphSizes;
  typoComponent?: ({ children }) => ReactNode;
} & ComponentProps<"button">>;

const getClassStyle = (color: ButtonColors, outline: boolean): string => {
  const buttonStyles: Record<
    ButtonColors,
    { default: string; outline?: string; }
  > = {
    primary: {
      default:
        "border-cgc-blue bg-cgc-blue text-cgc-white hover:border-cgc-blue-60 hover:bg-cgc-blue-60 disabled:border-cgc-blue-20 disabled:bg-cgc-blue-20 disabled:text-cgc-blue-80",
      outline:
        "border-cgc-blue text-cgc-blue hover:border-cgc-blue-80 hover:text-cgc-blue-80",
    },
    secondary: {
      default:
        "border-cgc-green bg-cgc-green text-cgc-blue hover:border-cgc-green-60 hover:bg-cgc-green-60 disabled:border-cgc-green-20 disabled:bg-cgc-green-20 disabled:text-cgc-green-80",
      outline:
        "border-cgc-green text-cgc-green hover:border-cgc-green-80 hover:bg-cgc-green-80 hover:text-cgc-black",
    },
    tertiary: {
      default:
        "border-cgc-white bg-cgc-white text-cgc-blue hover:border-cgc-blue-10 disabled:border-cgc-grey-10 disabled:bg-cgc-grey-10 disabled:text-cgc-grey-60",
      outline:
        "border-cgc-blue text-cgc-blue hover:border-cgc-blue-80 hover:text-cgc-blue-80",
    },
    danger: {
      default:
        "border-red-500 bg-red-500 text-cgc-white hover:border-red-600 hover:bg-red-600 disabled:border-red-300 disabled:bg-red-300 disabled:text-cgc-white",
      outline:
        "border-red-500 text-red-500 hover:border-red-600 hover:text-red-600",
    },
    whiteOutline: {
      default:
        "border-cgc-white bg-transparent text-white hover:border-cgc-white/90 hover:bg-cgc-blue/90 hover:text-cgc-white disabled:border-cgc-grey-10 disabled:bg-cgc-grey-10 disabled:text-cgc-grey-60",
      outline:
        "border-cgc-white text-cgc-blue hover:border-cgc-blue-80 hover:text-cgc-blue-80",
    },
    link: {
      default:
        "border-none hover:decoration-cgc-green-80 rounded-none hover:underline hover:decoration-[3px] hover:underline-offset-[5px] disabled:no-underline text-cgc-black disabled:text-cgc-grey-80 mx-2 px-0",
    },
    blog: {
      default:
        "border-cgc-blue bg-cgc-blue text-cgc-white hover:border-cgc-blue-60 hover:bg-cgc-blue-60 disabled:border-cgc-blue-20 disabled:bg-cgc-blue-20 disabled:text-cgc-blue-80 min-w-[260px] p-[26px] pb-[20px]",
    },
    yellow: {
      default:
        "disabled:cgc-yellow-20 disabled:text-grey-20 border-cgc-yellow-60 bg-cgc-yellow-60 text-cgc-black hover:border-cgc-yellow-60 hover:bg-cgc-yellow-60 disabled:border-cgc-yellow-20 normal-case",
    },
    christmasGreen: {
      default:
        "border-cgc-xmas-green23 bg-cgc-xmas-green23 text-cgc-white hover:border-cgc-xmas-green-light hover:bg-cgc-xmas-green-light disabled:border-cgc-xmas-green-light disabled:bg-cgc-xmas-green-light",
    },
    unstyled: {
      default: "border-none",
    },
  };

  return (
    buttonStyles[color][outline ? "outline" : "default"] ||
    buttonStyles[color].default ||
    ""
  );
};

export function Button({
  color = "primary",
  outline = false,
  isLoading = false,
  className,
  children,
  typoSize,
  typoComponent: TypoComponent,
  ...props
}: ButtonProps): JSX.Element {
  const defaultTypoSize = color === "blog" ? "sub2" : "sub3";
  const classStyle = getClassStyle(color, outline);

  return (
    <button
      className={clsx(
        "rounded border-2 px-2 pb-1.5 pt-2 uppercase",
        outline && "bg-white",
        classStyle,
        className,
      )}
      disabled={isLoading || props.disabled}
      type="button"
      {...props}
    >
      {TypoComponent ? (
        <TypoComponent>
          {isLoading ? <Spinner className='inline-block text-inherit m-0' size='sm' /> : children}
        </TypoComponent>
      ) : (
        <Paragraph as="span" size={typoSize ?? defaultTypoSize}>
          {isLoading ? <Spinner className='inline-block text-inherit m-0' size='sm' /> : children}
        </Paragraph>
      )}
    </button>
  );
}
